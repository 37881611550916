import { useConnect } from '@lore/query-connect';
import useTags from './useTags';

export default function useEventTags(event, type, timestamp) {
  const tags = useTags();

  const extraArgs = timestamp ? {
    createdAt: {
      $lte: timestamp
    }
  } : {};

  const tagNodes = useConnect('tagNode.find', {
    where: {
      eager: {
        $where: {
          tag: {
            $where: {
              type: type
            }
          },
          eventId: event.id,
          ...extraArgs,
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  }, {
    enabled: !!event.id
  });

  return {
    state: tagNodes.state,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _meta: 'generated in useEventTags',
      }
    }),
    _tagNodes: tagNodes
  }
}
