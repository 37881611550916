import React from 'react';
import Navigation from '../../dialogs_common/Navigation';
import Urls from './Urls';

export default function useEvaluationNavigation(evaluation, step) {
  const steps = [
    Urls(evaluation).STUDENTS,
    Urls(evaluation).ENGAGED,
    Urls(evaluation).SKILLS,
    Urls(evaluation).AWARENESS,
    Urls(evaluation).PRACTICED,
    Urls(evaluation).EXPANDED,
    Urls(evaluation).CONFIDENCE,
    Urls(evaluation).SUPPORTED,
    Urls(evaluation).EXCITEMENT,
    Urls(evaluation).UPLOADS,
    Urls(evaluation).RECOMMEND,
    Urls(evaluation).PARTICIPATE,
    Urls(evaluation).UNFOLDED_REFLECTION,
    Urls(evaluation).LEARNER_REFLECTION,
    Urls(evaluation).PERSONAL_REFLECTION,
    Urls(evaluation).OTHER_REFLECTION
  ];
  const activeStep = steps.indexOf(step);

  return (
    <Navigation
      v5={true}
      activeStep={activeStep + 1}
      steps={steps.length}
    />
  );
};
