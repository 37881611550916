import getQueryUtils from './queryUtils';

export default function useLocationCondition(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'locationCondition'
  ]);

  const value = queryUtils.params.locationCondition;

  if (
    value.radius &&
    value.address &&
    value.latitude &&
    value.longitude
  ) {
    return {
      _distance: {
        radius: value.radius,
        latitude: value.latitude,
        longitude: value.longitude,
        // address: value.address
      }
    };
  }

  return {};
}
