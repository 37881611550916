import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import Dialog from '../../dialogs/evaluation_event_educator';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import EmptyState from '../../components/EmptyState';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function Launcher(props) {
  const config = useDialogConfig();

  const evaluationEventEducator = useConnect('evaluationEventEducator.byId', {
    id: config.params.evaluationEventEducatorId
  });

  if (evaluationEventEducator.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (evaluationEventEducator.state === PayloadStates.ERROR_FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-lg" overflow={false}>
        <CloseDialogButton onCancel={props.onClose} />
        <EmptyState
          border={false}
          icon={ExclamationTriangleIcon}
          title="You do not have permission to view this evaluation."
          subtitle="Only the intended recipient can view it."
        />
      </DialogTemplate>
    );
  }

  return (
    <Dialog
      evaluationEventEducator={evaluationEventEducator}
      onClose={props.onClose}
    />
  );
};
