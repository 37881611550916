import React from 'react';
import Users from './Users';
import Uploads from './Uploads';
import Header from './Header';
import Ages from './Ages';
import Subjects from './Subjects';
import Card from '../../../components/Card';
import Creator from './Creator';
import Students from './Students';
import Experience from './Experience';
import Skills from './Skills';
import Testimonials from './sections/Testimonials';
import Photographs from './sections/Photographs';
import DocumentUploads from './sections/Uploads';
import Idea from './Idea';
import Journey from './Journey';
import Impact from './Impact';
import StewardButton from './StewardButton';

export default function ProjectPast(props) {
  const { project } = props;

  return (
    <div className="space-y-8">
      <div className="flex flex-col xl:flex-row mb-0x xl:mb-8x gap-8">
        <div className="w-full pr-0 xl:w-2/3 space-y-8">
          <Header project={project} />
          <Idea project={project} />
          <Journey project={project} />
          <Impact project={project} />
          <Testimonials project={project} />
          <Users project={project} />
          {/*<Uploads project={project} />*/}
          <Photographs project={project} />
          <DocumentUploads project={project} />
        </div>
        <div className="hidden xl:block w-full xl:w-1/3 space-y-8">
          <Creator project={project} />
          <Card>
            <div className="px-6 py-6 space-y-6">
              <Ages project={project} />
              <Students project={project}/>
              <Subjects project={project} />
              <Skills project={project} />
              <Experience project={project} />
            </div>
          </Card>
          <StewardButton project={project} />
        </div>
      </div>
    </div>
  );
};
