import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import useRouter from '../../../hooks/useRouter';
import Loader from '../../../components/Loader';
import SolidButton from '../../../components/Button/SolidButton';
import BubbleLayout from '../BubbleLayout';
import BubbleTemplate from '../BubbleTemplate';
import Urls from '../Urls';

export default function Layout(props) {
  const { history, match } = useRouter();

  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="End"
      >
        <Loader/>
      </BubbleLayout>
    );
  }

  return (
    <BubbleLayout
      evaluation={evaluation}
      page="End"
    >
      <BubbleTemplate
        title="Thank you!"
        subtitle={(
          <>
            <div>
              We really appreciate you taking the time to strengthen your community's
              ecosystem. Your feedback has been shared with your community organizers
              for them to take any needed action.
            </div>
          </>
        )}
      >
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton onClick={() => history.push(Urls(evaluation).PROJECT_VIEW)}>
            Close
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 font-semibold" to={Urls(evaluation).OTHER_REFLECTION}>
            Back
          </Link>
        </div>
      </BubbleTemplate>
    </BubbleLayout>
  );
};
