import _ from 'lodash';
import { DocumentDuplicateIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import useRouter from '../../../hooks/useRouter';

export default function useActionLinks(event) {
  const launch = useDialogLauncher();

  const { history } = useRouter();

  const isClosed = (
    event.data.completed ||
    event.data.cancelled
  );

  function onChangeVisibility() {
    launch(DialogManager.EventVisibility.Config(event.id));
  }

  function onDuplicate() {
    launch(DialogManager.EventDuplicate.Config(event.id));
  }

  return _.remove([
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Duplicate Event',
      onClick: onDuplicate,
      active: false
    },
    {
      type: 'button',
      icon: EyeIcon,
      label: event.data.published ?
        'Set Event Visibility' :
        'Set Event Visibility',
      onClick: onChangeVisibility,
      active: event.data.published,
      activeColor: 'green'
    },
    isClosed && {
      type: 'button',
      icon: PencilIcon,
      label: 'Edit Event Details',
      onClick: () => history.push('/edit')
    }
  ], entry => entry);
};
