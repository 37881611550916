import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Outcome(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Outcome
      </Category>
      <div className="space-y-3">
        <Entry
          title="Tell us a bit about how your project or event unfolded."
          description={evaluation.data.unfoldedReflection || '-'}
        />
        <Entry
          title="How were your learners impacted as a result of this experience?"
          description={evaluation.data.learnerReflection || '-'}
        />
        <Entry
          title="How were you impacted as a result of this experience?"
          description={evaluation.data.personalReflection || '-'}
        />
        <Entry
          title="Is there anything else you want to share about the impact of your community-engaged learning experiences and/or CommunityShare?"
          description={evaluation.data.otherReflection || '-'}
        />
      </div>
    </ContentCard>
  );
}
