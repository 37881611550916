import React from 'react';
import { useUser } from '@lore/auth';
import OptionCard from '../../my-network/OptionCard';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { stringify } from 'query-string';
import { UsersIcon } from '@heroicons/react/24/outline';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import useQuery from '../../people-partners/useQuery';

export default function NearbyPartners(props) {
  const currentUser = useUser();

  const site = useConnect('site.first', {
    where: {
      eager: {
        $where: {
          roles: {
            $where: {
              userId: currentUser.id
            }
          }
        }
      }
    }
  });

  const params = {
    search: {
      // networks: `${currentUser.data.networkId}`,
      locationCondition: site.state === PayloadStates.RESOLVED ? JSON.stringify({
        address: site.data.zipCode,
        latitude: site.data.latitude,
        longitude: site.data.longitude,
        radius: 10
      }) : undefined
    }
  };

  const users = useQuery(params);

  if (
    site.state === PayloadStates.FETCHING ||
    users.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (
    site.state === PayloadStates.NOT_FOUND ||
    users.meta.totalCount === 0
  ) {
    return null;
  }

  return (
    <OptionCard
      count={users.meta?.totalCount}
      // imageUrl={bookshelfImage}
      icon={UsersIcon}
      title="Partners near you"
      subtitle="Any partner 10 miles or less from your associated school site."
      linkText="View"
      to={{
        pathname: '/partners',
        search: stringify(params.search)
      }}
    />
  );
}
