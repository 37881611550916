import React from 'react';
import ActionBarMenu from './ActionBarMenu';
import PageSelectMenu from './PageSelectMenu';

export default function ActionBar(props) {
  const { event } = props;

  return (
    <div>
      <div className="hidden lg:block">
        <ActionBarMenu event={event} />
      </div>
      <div className="block lg:hidden">
        <PageSelectMenu event={event} />
      </div>
    </div>
  );
};
