import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: [
          {
            $or: words.map(function(word) {
              return {
                _project: {
                  creator: {
                    $ilike: {
                      where: {
                        firstName: `%${word}%`
                      }
                    }
                  }
                }
              };
            }).concat(words.map(function(word) {
              return {
                _project: {
                  creator: {
                    $ilike: {
                      where: {
                        lastName: `%${word}%`
                      }
                    }
                  }
                }
              };
            }))
          },
          {
            $or: words.map(function(word) {
              return {
                _event: {
                  creator: {
                    $ilike: {
                      where: {
                        firstName: `%${word}%`
                      }
                    }
                  }
                }
              };
            }).concat(words.map(function(word) {
              return {
                _event: {
                  creator: {
                    $ilike: {
                      where: {
                        lastName: `%${word}%`
                      }
                    }
                  }
                }
              };
            }))
          }
        ]
      }
    }
  }
}
