import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';
import useEvaluationNavigation from '../useEvaluationNavigation';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationEventEducator.byId', {
    id: Number(match.params.evaluationId)
  });

  const navigation = useEvaluationNavigation(evaluation, Urls(evaluation).PARTICIPATE);

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Participate"
      title="Would you be willing to participate in a 1-1 conversation about your experiences with CommunityShare?"
      description="Note: a stipend will be provided"
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls(evaluation).RECOMMEND)}
        onNext={(newData) => history.push(Urls(evaluation).UNFOLDED_REFLECTION)}
        onSkip={() => history.push(Urls(evaluation).UNFOLDED_REFLECTION)}
        onExit={() => history.push(Urls(evaluation).EVENT_VIEW)}
      />
    </ParentLayout>
  );
};
