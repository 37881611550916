import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import EvaluationUpload from './EvaluationUpload';
import UploadImageDestroyable from '../../evaluation-project-educator/Uploads/UploadImageDestroyable';
import UploadDocumentDestroyable from '../../evaluation-project-educator/Uploads/UploadDocumentDestroyable';
import FormSection from '../../_post-opportunity/forms/FormSection';
import FormField from '../../../forms/_common/FormField';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import Card from '../../../components/Card';
import getUploadInfo from '../../evaluation-project-educator/Uploads/getUploadInfo';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    evaluation,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          evaluationEventEducatorId: evaluation.id
        }
      }
    }
  })

  const [data, setData] = useState(_.pick(evaluation.data, [
    'releasePermission'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('evaluationEventEducator.update', evaluation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onUpload(upload) {
    // lore.actions.upload.create({
    //   evaluationEventEducatorId: evaluation.id,
    //   uploadId: upload.id
    // }).payload
  }

  const evaluationImages = {
    data: evaluationUploads.data.filter(function(evaluationUpload) {
      return getUploadInfo(evaluationUpload.data.name).isImage;
    })
  };

  const evaluationDocuments = {
    data: evaluationUploads.data.filter(function(evaluationUpload) {
      return !getUploadInfo(evaluationUpload.data.name).isImage;
    })
  };

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-8">
                  <Card className="p-5">
                    <div className="border border-dashed border-cs-orange-500 bg-cs-orange-500 bg-opacity-20 rounded-lg text-center py-20">
                      <EvaluationUpload
                        evaluation={evaluation}
                        onUpload={onUpload}
                      />
                    </div>
                  </Card>
                  {/*{evaluationUploads.data.length > 0 && (*/}
                  {/*  <FormSection*/}
                  {/*    title="Do you have permission to share these images?"*/}
                  {/*    description={(*/}
                  {/*      <>*/}
                  {/*        Ideally, you will have a photo release for all individuals under 18 or*/}
                  {/*        follow your district's policies around sharing student images. They*/}
                  {/*        would be used by CommunityShare and/or your local stewards. They might be*/}
                  {/*        shared with funders or on social media to celebrate your success.*/}
                  {/*      </>*/}
                  {/*    )}*/}
                  {/*  >*/}
                  {/*    <FormField name="releasePermission" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                  {/*      {(field) => (*/}
                  {/*        <>*/}
                  {/*          <RadioButtonField*/}
                  {/*            name="releasePermission"*/}
                  {/*            value={field.value === true ? 'Yes' : field.value === false ? 'No' : ''}*/}
                  {/*            error={field.error}*/}
                  {/*            placeholder="Select..."*/}
                  {/*            options={{*/}
                  {/*              data: [*/}
                  {/*                'Yes',*/}
                  {/*                'No'*/}
                  {/*              ].map((option) => {*/}
                  {/*                return {*/}
                  {/*                  id: option,*/}
                  {/*                  data: {*/}
                  {/*                    name: option*/}
                  {/*                  }*/}
                  {/*                }*/}
                  {/*              })*/}
                  {/*            }}*/}
                  {/*            optionLabel="name"*/}
                  {/*            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}*/}
                  {/*            onChange={(value) => {*/}
                  {/*              value === 'Yes' ? field.onChange(true) : field.onChange(false)*/}
                  {/*            }}*/}
                  {/*          />*/}
                  {/*        </>*/}
                  {/*      )}*/}
                  {/*    </FormField>*/}
                  {/*  </FormSection>*/}
                  {/*)}*/}
                  {evaluationImages.data.length > 0 && (
                    <FormSection title="Event Images">
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {evaluationImages.data.map(function(evaluationUpload) {
                          return (
                            <UploadImageDestroyable
                              key={evaluationUpload.id || evaluationUpload.cid}
                              upload={evaluationUpload}
                            />
                          );
                        })}
                      </div>
                    </FormSection>
                  )}
                  {evaluationDocuments.data.length > 0 && (
                    <FormSection title="Event Documents">
                      <div className="grid grid-cols-1 sm:grid-cols-2x gap-6">
                        {evaluationDocuments.data.map(function(evaluationUpload) {
                          return (
                            <UploadDocumentDestroyable
                              key={evaluationUpload.id || evaluationUpload.cid}
                              upload={evaluationUpload}
                            />
                          );
                        })}
                      </div>
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
