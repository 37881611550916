import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import RatingTitle from '../RatingTitle';
import useRouter from '../../../hooks/useRouter';
import useEvaluationNavigation from '../useEvaluationNavigation';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  });

  const navigation = useEvaluationNavigation(evaluation, Urls(evaluation).SKILLS);

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Social-Emotional Skills"
      title={(
        <RatingTitle>
          built social-emotional skills
        </RatingTitle>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls(evaluation).ENGAGED)}
        onNext={(newData) => history.push(Urls(evaluation).AWARENESS)}
        onSkip={() => history.push(Urls(evaluation).AWARENESS)}
        onExit={() => history.push(Urls(evaluation).PROJECT_VIEW)}
      />
    </ParentLayout>
  );
};
