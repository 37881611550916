import getQueryUtils from './queryUtils';

export default function useDistanceFilterParam(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'locationCondition'
  ]);

  const value = queryUtils.params.locationCondition;

  if (
    value.radius &&
    value.address &&
    value.latitude &&
    value.longitude
  ) {
    return {
      $or: [
        {
          _project: {
            _distance: {
              radius: value.radius,
              latitude: value.latitude,
              longitude: value.longitude,
              // address: state.value.address
            }
          }
        },
        {
          _event: {
            _distance: {
              radius: value.radius,
              latitude: value.latitude,
              longitude: value.longitude,
              // address: state.value.address
            }
          }
        }
      ]
    };
  }
}
