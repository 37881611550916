export default function(evaluation) {
  return {
    START: `/evaluation-project-educator/${evaluation.id}/start`,
    STUDENTS: `/evaluation-project-educator/${evaluation.id}/students`,

    ENGAGED: `/evaluation-project-educator/${evaluation.id}/engaged`,
    SKILLS: `/evaluation-project-educator/${evaluation.id}/skills`,
    AWARENESS: `/evaluation-project-educator/${evaluation.id}/awareness`,
    PRACTICED: `/evaluation-project-educator/${evaluation.id}/practiced`,

    EXPANDED: `/evaluation-project-educator/${evaluation.id}/expanded`,
    CONFIDENCE: `/evaluation-project-educator/${evaluation.id}/confidence`,
    SUPPORTED: `/evaluation-project-educator/${evaluation.id}/supported`,
    EXCITEMENT: `/evaluation-project-educator/${evaluation.id}/excitement`,

    UPLOADS: `/evaluation-project-educator/${evaluation.id}/uploads`,
    RECOMMEND: `/evaluation-project-educator/${evaluation.id}/recommend`,
    PARTICIPATE: `/evaluation-project-educator/${evaluation.id}/participate`,

    UNFOLDED_REFLECTION: `/evaluation-project-educator/${evaluation.id}/unfolded-reflection`,
    LEARNER_REFLECTION: `/evaluation-project-educator/${evaluation.id}/learner-reflection`,
    PERSONAL_REFLECTION: `/evaluation-project-educator/${evaluation.id}/personal-reflection`,
    OTHER_REFLECTION: `/evaluation-project-educator/${evaluation.id}/other-reflection`,

    END: `/evaluation-project-educator/${evaluation.id}/end`,
    PROJECT_MANAGE: `/project/${evaluation.data.projectId}/manage`,
    PROJECT_VIEW: `/project/${evaluation.data.projectId}/view`
  }
};
