import TagTypes from '../../constants/TagTypes';

const timestamp = new Date().toISOString();

const REQUIRED_FIELDS_PUBLIC = {
  $and: [
    // Must provide activity type and description
    {
      activityId: {
        $exists: true
      },
      description: {
        $not: ''
      }
    },
    // Date must be provided and in the future
    {
      $or: [
        {
          $and: [
            {
              specificDate: 'Yes'
            },
            {
              date: {
                $gte: timestamp
              }
            }
          ]
        },
        {
          $and: [
            {
              specificDate: 'No'
            },
            {
              startDate: {
                $exists: true
              }
            },
            {
              endDate: {
                $gte: timestamp
              }
            }
          ]
        }
      ]
    },
    // Must specify a participant
    {
      tags: {
        $where: {
          type: TagTypes.Participant
        },
        $count: {
          operator: 'gte',
          value: 1
        }
      }
    },
    // Must specify a method of interaction
    {
      tags: {
        $where: {
          type: TagTypes.InteractionType
        },
        $count: {
          operator: 'gte',
          value: 1
        }
      }
    },
    // Must be visible, and not completed or cancelled
    {
      cancelled: false
    },
    {
      completed: false
    },
    {
      published: true
    }
  ]
}

export default REQUIRED_FIELDS_PUBLIC;
