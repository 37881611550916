import getQueryUtils from './queryUtils';
import { REQUIRED_PARTNER_FIELDS_ARRAY } from '../../utils/user/REQUIRED_FIELDS';
import { useConnect } from '@lore/query-connect';
import { hasPartnerIntentQuery } from '../../utils/query-utils/hasIntentQuery';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 16,
    timestamp,
    distance = {}
  } = props;

  const queryUtils = getQueryUtils(search, [
    'searchPartner',
    'name',
    'organization',
    'ages',
    'activities',
    'expertiseTypes',
    'experiences',
    'guidances',
    'groups',
    'locationCondition'
  ], timestamp);

  return useConnect('user.find', {
    where: {
      // distance: _.pick(distance, ['latitude', 'longitude']),
      eager: {
        $where: {
          $and: [
            ...queryUtils.where,
            hasPartnerIntentQuery,
            ...REQUIRED_PARTNER_FIELDS_ARRAY
          ]
        }
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
