import { useConnect } from '@lore/query-connect';

export default function useStoriesForUser(user) {
  return useConnect('story.find', {
    where: {
      eager: {
        $where: {
          userNodes: {
            $where: {
              userId: user.id,
            }
          }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
};
