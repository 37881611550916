import React from 'react';
import _ from 'lodash';
import SelectField from '../../../forms/_fields_v2/SelectField';
import useNavigationLinks from './useNavigationLinks';
import useActionLinks from './useActionLinks';
import useDialogConfig from '../../_hooks/useDialogConfig';
import useDialogRouter from '../../_hooks/useDialogRouter';

export default function PageSelectMenu(props) {
  const { offer } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);
  const { location, history } = router;

  const pageLinks = useNavigationLinks(offer);
  const actionLinks = useActionLinks(offer);

  const pathnameValueMap = {
    [`/invite`]: `/invite-populate`
  };

  const onClickMap = _.reduce(actionLinks, function(result, link) {
    result[link.label] = link.onClick;
    return result;
  }, {});

  return (
    <SelectField
      label="Page"
      value={pathnameValueMap[location.pathname] || location.pathname}
      // placeholder="Select page..."
      options={{
        data: pageLinks.map(function(link, index) {
          return {
            id: link.to,
            data: {
              name: link.label
            }
          };
        }).concat(actionLinks.map(function(link, index) {
          return {
            id: link.label,
            data: {
              name: link.label
            }
          };
        }))
      }}
      optionLabel="name"
      onChange={function(id) {
        if (onClickMap[id]) {
          onClickMap[id]();
        } else {
          history.push(id);
        }
      }}
    />
  );
}
