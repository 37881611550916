import { useConnect } from '../../../hooks/@lore/query-connect';
import REQUIRED_FIELDS_PAST from '../events/REQUIRED_FIELDS_PAST';

export default function usePastInvitationsForUser(user) {
  return useConnect('event.find', {
    where: {
      eager: {
        $where: {
          invitations: {
            $where: {
              userId: user.id,
              response: 'Yes'
            }
          },
          $and: REQUIRED_FIELDS_PAST.$and
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
