import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import locationCondition from './locationCondition';
import name from './name';
import organization from './organization';
import searchPartner from './searchPartner';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          tags: {
            $where: {
              id: {
                $in: values
              }
            }
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          tagTrees: {
            $where: {
              parentId: {
                $in: values
              }
            }
          }
        }
      }
    }
  };
}

const attributes = {
  activities: tag(),
  ages: tag(),
  experiences: tag(),
  expertiseTypes: tagTree(),
  groups: tag(),
  guidances: tag(),
  locationCondition,
  name,
  organization,
  searchPartner
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
