import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Datepicker } from 'tw-elements';
// import Datepicker from 'tw-elements/dist/src/js/forms/datepicker';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '@lore/auth';
import convertDateToInputDate from './convertDateToInputDate';

DateField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  date: PropTypes.string
};

DateField.defaultProps = {
  disabled: false
};

export default function DateField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    date
  } = props;

  const currentUser = useUser();

  // onChange will update (and likely call setState(nextState) but
  // if we don't update the reference used in useEffect we will end
  // up setting data to old values for other fields
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  const [formId] = useState(`f-${uuidv4()}`);

  useEffect(() => {
    const datepickerNode = document.querySelector(`#${formId}`);
    const datepicker = new Datepicker(datepickerNode, {
      confirmDateOnSelect: true,
      removeClearBtn: true
    });

    datepickerNode.addEventListener("dateChange.te.datepicker", (event) => {
      const year = event.date.getFullYear();
      const month = event.date.getMonth() + 1;
      const day = event.date.getDate();

      const nextDate = [
        year,
        month < 10 ? `0${month}` : month,
        day < 10 ? `0${day}` : day
      ].join('-');

      onChangeRef.current(nextDate);
    });
  }, []);

  // const dateValue = DateTime.fromISO(date).setZone(currentUser.data.timezone).toLocaleString(DateTime.DATE_MED);
  const dateValue = convertDateToInputDate(date);

  return (
    <div
      id={formId}
      // className="relative"
      data-te-datepicker-init
      data-te-format="mmm d, yyyy"
    >
      {label ? (
        <label>
          {label}
        </label>
      ): null}
      <div className="z-0 relative flex items-center">
        <input
          type="text"
          value={dateValue}
          // className="rounded-md shadow-cs-flat border border-cs-gray-300 w-full px-3 py-2 text-cs-gray-900"
          className="rounded-md shadow-cs-flat border border-cs-gray-300 w-full px-5 py-4 text-cs-gray-900"
          onChange={() => {}}
          data-te-toggle={formId}
          data-te-datepicker-toggle-ref
          data-te-datepicker-toggle-button-ref
        />
        <CalendarIcon className="h-6 w-6 text-cs-gray-700 absolute right-5 z-10"/>
      </div>
      {error ? (
        <div className="invalid-feedback">
          {error}
        </div>
      ) : null}
      {description ? (
        <small className="form-text text-muted">
          {description}
        </small>
      ) : null}
    </div>
  );
}
