import React from 'react';

export default function RatingTitle(props) {
  const {
    supertitle = 'Please rate the extent to which your students ',
    subtitle = ' as a result of this activity.',
    children
  } = props;

  return (
    <div>
      <span>{supertitle}</span>
      <span className="underline">{children}</span>
      <span>{subtitle}</span>
    </div>
  );
};
