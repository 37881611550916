import React, { useCallback, useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/offers/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from '../../pages/projects/useStewardshipFilter.object';
import useInteractionTypesFilterParam from '../../hooks/useInteractionTypesFilterParam';
import useDistanceFilterParam from '../../hooks/useDistanceFilterParam';
import REQUIRED_FIELDS_PUBLIC from '../../pages/offers/REQUIRED_FIELDS_PUBLIC';
import SolidButton from '../../components/Button/SolidButton';
import { attributeKeys } from '../../pages/offers/queryUtils';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { PayloadStates } from '@lore/utils';
import DisclosureBaseFilter from '../../forms/_fields_v2/DisclosureBaseFilter';
import _ from 'lodash';
import SolidButtonLoader from '../../components/Button/SolidButtonLoader';

export default function OfferFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));
  const [querySearch, setQuerySearch] = useState(parse(location.search));

  const debounceSetQuerySearch = useCallback(_.debounce(setQuerySearch, 250), []);

  const stewardshipFilter = useStewardshipFilter(search);
  const interactionTypesFilter = useInteractionTypesFilterParam(search);
  const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    title: 'title',
    name: 'name',
    organization: 'organization',
    searchOffer: 'searchOffer',
    activities: 'activities',
    guidances: 'guidances',
    subjects: 'subjects',
    groups: 'groups',
    participants: 'participants',
    times: 'times',
    supplies: 'supplies',
    costs: 'costs'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onCancel();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceSetQuerySearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...stewardshipFilter,
            ...interactionTypesFilter,
            ...distanceFilter,
            tagNodes: {
              $where: {
                tagId: tag.id
              }
            },
            $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getActivityParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...stewardshipFilter,
            ...interactionTypesFilter,
            ...distanceFilter,
            activityId: tag.id,
            $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getCostParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...stewardshipFilter,
            ...interactionTypesFilter,
            ...distanceFilter,
            hasCost: tag.data.name === 'Yes' ? 'Yes' : 'No',
            $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search: querySearch
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div>*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.title).value,
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.organization).value,
                searchUtils(filterFields.searchOffer).value
              ]}
            >
              <SearchFilter
                label="Offer title"
                // placeholder="Enter title..."
                description="Find offers with a title that contains the phrase you type."
                value={searchUtils(filterFields.title).value}
                onChange={searchUtils(filterFields.title).onChangeValue}
              />
              <SearchFilter
                label="Partner name"
                // placeholder="Enter name..."
                description="Find offers created by a partner whose first or last name matches one of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="Partner Organization"
                // placeholder="Enter organization name..."
                description="Find offers created by a partner who is affiliated with an organization whose name contains the phrase you type."
                value={searchUtils(filterFields.organization).value}
                onChange={searchUtils(filterFields.organization).onChangeValue}
              />
              <SearchFilter
                label="Search"
                // placeholder="Enter search term..."
                description="Find offers that have a specific phrase in their description, audience, or objective."
                value={searchUtils(filterFields.searchOffer).value}
                onChange={searchUtils(filterFields.searchOffer).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          {/*<Divider />*/}
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Activity}
            label="Activity"
            getParams={getActivityParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Guidance}
            label="Professional competencies"
            getParams={getTagParams}
            value={searchUtils(filterFields.guidances).value}
            onChange={searchUtils(filterFields.guidances).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Subject}
            label="Subject areas"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Group}
            label="Youth groups"
            getParams={getTagParams}
            value={searchUtils(filterFields.groups).value}
            onChange={searchUtils(filterFields.groups).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Participant}
            label="Participants"
            getParams={getTagParams}
            value={searchUtils(filterFields.participants).value}
            onChange={searchUtils(filterFields.participants).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Time}
            label="Time"
            getParams={getTagParams}
            value={searchUtils(filterFields.times).value}
            onChange={searchUtils(filterFields.times).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="offer"
            type={TagTypes.Supply}
            label="Supplies"
            getParams={getTagParams}
            value={searchUtils(filterFields.supplies).value}
            onChange={searchUtils(filterFields.supplies).onChangeArray}
          />
          <DisclosureBaseFilter
            modelName="offer"
            label="Cost"
            getParams={getCostParams}
            value={searchUtils(filterFields.costs).value}
            onChange={searchUtils(filterFields.costs).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                {
                  id: 1,
                  data: {
                    name: 'Yes'
                  }
                },
                {
                  id: 2,
                  data: {
                    name: 'Free'
                  }
                }
              ]
            }}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          {result._isPreviousData ? (
            <SolidButton onClick={onSubmit}>
              <SolidButtonLoader />
            </SolidButton>
          ) : (
            <SolidButton onClick={onSubmit}>
              Show {result.meta?.totalCount} results
            </SolidButton>
          )}
        </div>
      </div>
    </DialogTemplate>
  );
};
