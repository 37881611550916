import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import getUploadInfo from '../../evaluation-project-educator/Uploads/getUploadInfo';
import getUrlForUpload from '../../user/User/getUrlForUpload';
import { getActivityIconMap } from '../../../maps/activityIconMap';

Image.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Image(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const upload = useConnect('upload.first', {
    where: {
      eager: {
        $where: {
          evaluationProjectEducator: {
            $where: {
              projectId: project.id
            }
          },
          $or: [
            'png',
            'jpeg',
            'jpg',
            'heic',
            'gif'
          ].map(function(value) {
            return {
              name: {
                $ilike: `%.${value}`
              }
            };
          })
        }
      }
    }
  });

  const info = getUploadInfo(upload.data.name);
  const url = getUrlForUpload(upload);
  const iconMap = getActivityIconMap(activity);

  return (
    <div className="h-48 rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-300">
      {/*<div className="absolute z-10 top-2 right-4">*/}
      {/*  <ProjectBookmark*/}
      {/*    project={project}*/}
      {/*    user={currentUser}*/}
      {/*  />*/}
      {/*</div>*/}
      {info.isImage ? (
        <img
          className="absolute left-0 top-0 object-cover object-center h-full w-full"
          src={url}
          alt="image"
        />
      ) : (
        <div className="flex flex-col items-center justify-center h-full space-y-1">
          {/*<info.icon.hack className="h-20 w-20 text-white"/>*/}
          {/*<div className="text-white">*/}
          {/*  {info.extension ? `.${info.extension}` : '[filetype unknown]'}*/}
          {/*</div>*/}
          <iconMap.icon className="h-12 w-12 stroke-1 text-gray-100"/>
          <div className="text-sm text-gray-100">
            {activity.data.name}
          </div>
        </div>
      )}
    </div>
  );
};
