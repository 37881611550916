import React from 'react';
import getUploadInfo from '../../../pages/evaluation-project-educator/Uploads/getUploadInfo';
import classNames from 'classnames';
import getUrlForUpload from '../../../pages/user/User/getUrlForUpload';

export default function Photograph(props) {
  const { evaluationUpload } = props;

  const info = getUploadInfo(evaluationUpload.data.name);
  const url = getUrlForUpload(evaluationUpload);

  return (
    <div className="space-y-2">
      {info.isImage ? (
        <div className="aspect-w-3 aspect-h-2">
          <img
            className="object-cover shadow-cs-flat rounded-lg"
            src={url}
            alt=""
          />
        </div>
      ) : (
        <div className={classNames(
          'aspect-w-3 aspect-h-2',
          'bg-cs-gray-500 rounded-lg overflow-hidden',
          'shadow-cs-flat -m-px border border-cs-gray-100'
        )}>
          {/*<img className="object-cover shadow-lg rounded-lg" src={url} alt="" />*/}
          <div className="flex flex-col items-center justify-center h-full">
            <info.icon.hack className="h-20 w-20 text-white"/>
            <div className="text-white">
              {info.extension ? `.${info.extension}` : '[filetype unknown]'}
            </div>
          </div>
        </div>
      )}
      <div className="text-sm">
        <a
          className="text-accent hover:text-primary underline"
          href={url}
          target="_blank"
        >
          {evaluationUpload.data.name}
        </a>
      </div>
    </div>
  );
}
