import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';

const timestamp = new Date().toISOString();

export default function usePastInvitations(props) {
  const currentUser = useUser();

  return useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          response: {
            $in: [
              'Yes',
              'Maybe'
            ]
          },
          // 'event.completed': false,
          // 'event.cancelled': false,
          $or: [
            // { 'event.completed': true },
            {
              event: {
                $where: {
                  completed: true
                }
              }
            },
            // { 'event.cancelled': true },
            {
              event: {
                $where: {
                  cancelled: true
                }
              }
            },
            {
              // 'event.specificDate': 'Yes',
              // 'event.date': {
              //   $lte: timestamp
              // },
              event: {
                $where: {
                  $and: [
                    {
                      specificDate: 'Yes'
                    },
                    {
                      date: {
                        $lte: timestamp
                      }
                    }
                  ]
                }
              }
            },
            {
              // 'event.specificDate': 'No',
              // 'event.endDate': {
              //   $lte: timestamp
              // },
              event: {
                $where: {
                  $and: [
                    {
                      specificDate: 'No'
                    },
                    {
                      endDate: {
                        $lte: timestamp
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
