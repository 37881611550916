import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function Research(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Research
      </Category>
      <div className="space-y-3">
        <Entry
          title="Would you be willing to participate in a 1-1 conversation about your experiences with CommunityShare?"
          description={evaluation.data.participate || '-'}
        />
      </div>
    </ContentCard>
  );
}
