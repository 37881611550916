import React from 'react';
import classNames from 'classnames';
import { useConfig } from '@lore/config';
import { useUser } from '@lore/auth';

export default function StewardButton(props) {
  const { project } = props;

  const currentUser = useUser();

  const config = useConfig();
  const stewardUrl = config.custom.stewardUrl();

  if (!currentUser.data.isStaff) {
    return null;
  }

  return (
    <div
      className={classNames(
        'bg-cs-orange-100 text-cs-orange-500 hover:opacity-75',
        'border-dotted border-2 border-orange-500',
        'rounded-lg text-center'
      )}
    >
      <a href={`${stewardUrl}/project/${project.id}`} target="_blank" className="py-3 px-3 block w-full">
        Open in steward platform
      </a>
    </div>
  );
}
