import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: words.map(function(word) {
          return {
            creator: {
              $where: {
                jobs: {
                  $where: {
                    organization: {
                      $where: {
                        name: {
                          $ilike: `%${word}%`
                        }
                      }
                    }
                  }
                }
              }
            }
          };
        })
      }
    }
  }
}
