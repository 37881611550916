import TagTypes from '../../constants/TagTypes';

const REQUIRED_FIELDS_PAST = {
  $and: [
    // Must provide activity type and description
    {
      activityId: {
        $exists: true
      }
    },
    {
      siteId: {
        $exists: true
      }
    },
    {
      interactionDescription: {
        $not: ''
      }
    },
    // Date must be provided
    {
      $or: [
        {
          $and: [
            {
              specificDate: 'Yes'
            },
            {
              date: {
                $exists: true
              }
            }
          ]
        },
        {
          $and: [
            {
              specificDate: 'No'
            },
            {
              startDate: {
                $exists: true
              }
            },
            {
              endDate: {
                $exists: true
              }
            }
          ]
        }
      ]
    },
    // Must specify a participant
    {
      tags: {
        $where: {
          type: TagTypes.Participant
        },
        $count: {
          operator: 'gte',
          value: 1
        }
      }
    },
    // Must specify a method of interaction
    {
      tags: {
        $where: {
          type: TagTypes.InteractionType
        },
        $count: {
          operator: 'gte',
          value: 1
        }
      }
    },
    // Must be visible, completed, and not cancelled
    {
      cancelled: false
    },
    {
      completed: true,
    },
    // {
    //   showInGallery: true
    // }
  ]
}

export default REQUIRED_FIELDS_PAST;
