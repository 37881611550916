import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
// import RadioButtonField from '../../../forms/_fields/RadioButtonField';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    evaluation
  } = props;

  const [data, setData] = useState(_.pick(evaluation.data, [
    'offerStatement',
    'learnStatement',
    'understandingStatement',
    'connectionStatement',
    'volunteerStatement'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('evaluationProjectPartner.update', evaluation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  const options = {
    scaleScores: [
      'Disagree',
      'Neutral',
      'Agree',
      'Strongly Agree'
    ]
  };

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    title="It helped me realize that I have something valuable to offer to students and teachers."
                  >
                    <FormField name="offerStatement" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="offerStatement"
                            // label="Learner(s) more engaged."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: options.scaleScores.map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={field.onChange}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  <FormSection
                    title="It helped me realize that I have things to learn from others who may be different than me."
                  >
                    <FormField name="learnStatement" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="learnStatement"
                            // label="It helped me realize that I have things to learn from others who may be different than me."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: options.scaleScores.map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  <FormSection
                    title="It increased my understanding of the realities of a teacher's job."
                  >
                    <FormField name="understandingStatement" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="understandingStatement"
                            // label="Learner(s) increased understanding of the real-world application of topics/content."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: options.scaleScores.map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  <FormSection
                    title="It increased my sense of belonging and sense of connection to my community."
                  >
                    <FormField name="connectionStatement" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="connectionStatement"
                            // label="Learner(s) increased understanding of the real-world application of topics/content."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: options.scaleScores.map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  <FormSection
                    title="I am likely to volunteer again."
                  >
                    <FormField name="volunteerStatement" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            className="mb-12"
                            name="volunteerStatement"
                            // label="Learner(s) acquired a new skill or mindset."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: options.scaleScores.map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    Next
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
