import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import Intent from './Intent';
// import educatorImage from '../../../../assets/images/sign-up-educator.jpg';
// import partnerImage from '../../../../assets/images/sign-up-partner.jpg';
import educatorImage from '../../../../assets/images/profile-intent-educator.jpg';
import partnerImage from '../../../../assets/images/profile-intent-partner.jpg';
import bothImage from '../../../../assets/images/profile-intent-both.jpg';

Intents.propTypes = {
  userIntents: PropTypes.object
};

export default function Intents(props) {
  const {
    user,
    intents,
    userIntents,
    selectable
  } = props;

  const educatorIntent = _.find(intents.data, intent => intent.data.name === 'Educator');
  const partnerIntent = _.find(intents.data, intent => intent.data.name === 'Partner');

  const isEducator = !!_.find(userIntents.data, intent => intent.data.id === educatorIntent.id);
  const isPartner = !!_.find(userIntents.data, intent => intent.data.id === partnerIntent.id);

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleIntent(intent) {
    const userIntent = _.find(userIntents.data, function(userIntent) {
      return userIntent._tag.id === intent.id;
    });

    if (!userIntent) {
      mutation_create.mutate({
        userId: user.id,
        tagId: intent.id
      }, {
        onSuccess: payload => userIntents._add(payload)
      });
    } else {
      mutation_destroy.mutate(userIntent._tagNode, {
        onSuccess: payload => userIntents._remove(payload)
      });
    }
  }

  function onCreateUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeCreate.Config(intent.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeUpdate.Config(userIntent._tagNode.id));
  }

  function onDestroyUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeDestroy.Config(userIntent._tagNode.id));
  }

  function setEducatorIntent(active) {
    if (active && !isEducator) {
      onToggleIntent(educatorIntent);
    }

    if (!active && isEducator) {
      onToggleIntent(educatorIntent);
    }
  }

  function setPartnerIntent(active) {
    if (active && !isPartner) {
      onToggleIntent(partnerIntent);
    }

    if (!active && isPartner) {
      onToggleIntent(partnerIntent);
    }
  }

  return (
    <Overlay isVisible={saving}>
      {/*<div className="flex flex-col md:flex-row space-x-0 md:space-x-12 space-y-12 md:space-y-0">*/}
      <div className="flex flex-col space-x-0 space-y-6">
        <Intent
          title="Connect with partners"
          avatar={educatorImage}
          description={(
            <>
              I'm a PreK-12 educator looking for partners to bring skills and opportunities to my learners.
            </>
          )}
          guidance={(
            <div className="bg-white bg-opacity-100 text-cs-gray-700 max-w-lg p-1">
              <div className="text-base">
                This is a great starting point for PreK-12 teachers!
              </div>
            </div>
          )}
          onClick={() => {
            setEducatorIntent(true);
            setPartnerIntent(false);
          }}
          selected={isEducator && !isPartner}
        />
        <Intent
          title="Share my skills and resources"
          avatar={partnerImage}
          description={(
            <>
              I want to share my experience, knowledge, and/or opportunities with educators and
              learners as a community partner.
            </>
          )}
          guidance={(
            <div className="bg-white bg-opacity-100 text-cs-gray-700 max-w-lg p-1">
              <div className="text-base">
                Perfect for community members or organizations looking to share skills and/or opportunities.
              </div>
            </div>
          )}
          onClick={() => {
            setEducatorIntent(false);
            setPartnerIntent(true);
          }}
          selected={isPartner && !isEducator}
        />
        <Intent
          title="Both"
          avatar={bothImage}
          description={(
            <>
              I want to collaborate with others to support my learners and share my own expertise.
            </>
          )}
          guidance={(
            <div className="bg-white bg-opacity-100 text-cs-gray-900 max-w-lg p-1">
              <div className="text-base font-semibold mb-1">
                This is a great option for:
              </div>
              <ul className="list-disc ml-6 text-base space-y-0">
                {[
                  'Educational organizations and libraries',
                  'School administrators',
                  'Those working with learners outside of school settings',
                  'Anyone interested in both connecting with community partners as well as contributing their skills as community partners'
                ].map(function(text, index) {
                  return (
                    <li key={index}>
                      {text}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          // onClick={() => onToggleIntent(partnerIntent)}
          onClick={() => {
            setEducatorIntent(true);
            setPartnerIntent(true);
          }}
          selected={isEducator && isPartner}
        />
      </div>
    </Overlay>
  );
}
