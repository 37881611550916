import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: words.map(function(word) {
          return {
            creator: {
              $where: {
                firstName: {
                  $ilike: `%${word}%`
                }
              }
            }
          };
        }).concat(words.map(function(word) {
          return {
            creator: {
              $where: {
                lastName: {
                  $ilike: `%${word}%`
                }
              }
            }
          };
        }))
      }
    }
  }
}
