import React from 'react';
import PropTypes from 'prop-types';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import IconSection from '../../_new_card_components/IconSection';

Location.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Location(props) {
  const { project } = props;

  const site = useConnect('site.byId', {
    id: project.data.siteId
  }, {
    enabled: !!project.data.siteId
  });

  if (!site) {
    return null;
  }

  if (site.state === PayloadStates.FETCHING) {
    return (
      <IconSection
        icon={MapPinIcon}
        title="..."
      />
    );
  }

  return (
    <IconSection
      icon={MapPinIcon}
      title={`${site.data.city}, ${site.data.state}`}
    />
  );
}
