import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import FormSection from '../../_post-opportunity/forms/FormSection';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
// import RadioButtonField from '../../../forms/_fields/RadioButtonField';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};

const MAX_LENGTH = 256;

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    evaluation
  } = props;

  const [data, setData] = useState(_.pick(evaluation.data, [
    'recommend',
    'improve',
    'testimonial',
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('evaluationProjectEducator.update', evaluation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      ...data,
      completed: true
    });
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (name === 'platform') {
      nextData.platformMessage = '';
    }

    setData(nextData);
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        platformMessage: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    // title="Would you recommend CommunityShare to other community members?"
                    // subtitle="Are the students in your class primarily..."
                  >
                    <FormField name="recommend" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="recommend"
                            // label="Age group..."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: [
                                'Yes',
                                'No'
                              ].map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  {data.recommend === 'Yes' && (
                    <FormSection
                      title="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer."
                      tooltip={(
                        <div className="space-y-4">
                          <div className="text-base w-80 text-cs-gray-900">
                            <strong>Example</strong>: "It was fabulous having Ana mentor our class STEM
                            projects. The partner helped my students discover a sense of purpose and
                            learn something new about our community."
                          </div>
                          <div className="w-80 text-base">
                            <strong>Example</strong>: "Having CommunityShare's human library made it easier to
                            find the right partner for my project. Our class got to do a deep dive and present
                            a meaningful product to our community. I love that their voice drove what we explored
                            with our partner."
                          </div>
                        </div>
                      )}
                    >
                      <FormField name="testimonial" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <>
                            <TextAreaField
                              // label="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer."
                              description={`${data.testimonial ? data.testimonial.length : 0}/${MAX_LENGTH} characters`}
                              rows={5}
                              value={field.value}
                              error={field.error}
                              placeholder="Enter testimonial..."
                              onChange={field.onChange}
                            />
                          </>
                        )}
                      </FormField>
                    </FormSection>
                  )}
                  {data.recommend === 'No' && (
                    <FormSection
                      title="Please tell us how we can improve."
                    >
                      <FormField name="improve" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <>
                            <TextAreaField
                              // label="Do you need any additional support?"
                              // explanation="Example: Working with the students helped me realize that I have my background and skills can make a real difference in a student's life. The kids helped me discover a sense of purpose and learn something new about my community. Thank you!"
                              description={`${data.improve ? data.improve.length : 0}/${MAX_LENGTH} characters`}
                              rows={5}
                              value={field.value}
                              error={field.error}
                              placeholder="Enter suggestion..."
                              onChange={field.onChange}
                            />
                          </>
                        )}
                      </FormField>
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    Next
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
