import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { useState } from 'react';

export default function useActiveQuestions(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  return useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          response: 'Connect',
          // 'project.completed': false,
          // 'project.cancelled': false,
          project: {
            $where: {
              $and: [
                {
                  completed: false
                },
                {
                  cancelled: false
                }
              ]
            }
          },
          $or: [
            {
              // 'project.specificDate': 'Yes',
              // 'project.date': {
              //   $gt: timestamp
              // },
              project: {
                $where: {
                  $and: [
                    {
                      specificDate: 'Yes'
                    },
                    {
                      date: {
                        $gt: timestamp
                      }
                    }
                  ]
                }
              }
            },
            {
              // 'project.specificDate': 'No',
              // 'project.endDate': {
              //   $gt: timestamp
              // },
              project: {
                $where: {
                  $and: [
                    {
                      specificDate: 'No'
                    },
                    {
                      endDate: {
                        $gt: timestamp
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
