import { extractObjectFromQuery } from '../../../utils/query-utils';

export default {
  param: extractObjectFromQuery(),
  query: function(value) {
    if (
      value.radius &&
      value.address &&
      value.latitude &&
      value.longitude
    ) {
      return {
        _distance: {
          radius: value.radius,
          latitude: value.latitude,
          longitude: value.longitude,
          // address: state.value.address
        }
      };
    }
  }
}
