import React from 'react';
import ActionBarMenu from './ActionBarMenu';
import PageSelectMenu from './PageSelectMenu';

export default function ActionBar(props) {
  const { project } = props;

  return (
    <div>
      <div className="hidden lg:block">
        <ActionBarMenu project={project} />
      </div>
      <div className="block lg:hidden">
        <PageSelectMenu project={project} />
      </div>
    </div>
  );
};
