import React from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';
import ClampableText from '../../components/ClampableText';
import MessageDeliveryStatus from './MessageDeliveryStatus';
import classNames from 'classnames';
import MessageProject from './MessageProject';
import MessageEvent from './MessageEvent';
import MessageOffer from './MessageOffer';
import Text from '../../typography';

export default function Message(props) {
  const { message, className } = props;

  const sender = useConnect('user.byId', {
    id: message.data.senderId
  });

  const user = useUser();

  if (sender.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const time = DateTime.fromISO(message.data.createdAt).setZone(user.data.timezone).toLocaleString(DateTime.TIME_SIMPLE_WITH_SHORT_OFFSET);
  const date = DateTime.fromISO(message.data.createdAt).setZone(user.data.timezone).toLocaleString(DateTime.DATE_MED);

  return (
    <div className={classNames(
      'flex space-x-3',
      className
    )}>
      <div className="flex">
        <Avatar
          size={10}
          src={sender.data.avatarUrl}
        />
      </div>
      <div className="flex-1 space-y-0.5x">
        <div className="flex items-center justify-betweenx space-x-3">
          <Text.BaseSemibold>
            {sender.data.firstName} {sender.data.lastName}
          </Text.BaseSemibold>
          <Text.SmGrayMedium className="space-x-1.5">
            {message.data.createdAt && (
              <span>{time}</span>
            )}
            {/*<span>·</span>*/}
            {/*<span>{date}</span>*/}
            {(
              message.data.sendgridMessageId ||
              message.data.postmarkMessageId
            ) && (
              <>
                <span>·</span>
                <MessageDeliveryStatus
                  className="text-cs-gray-500"
                  message={message}
                />
              </>
            )}
          </Text.SmGrayMedium>
        </div>
        <div className="break-words text-cs-gray-700" style={{ overflowWrap: 'anywhere' }}>
          <ClampableText markup={true}>
            {message.data.content}
          </ClampableText>
        </div>
        {(
          message.data.projectId ||
          message.data.eventId ||
          message.data.offerId
        ) && (
          <div className="flex items-center mt-1">
            <Text.SmGrayMedium className="space-x-1.5">
              <MessageProject message={message} />
              <MessageEvent message={message} />
              <MessageOffer message={message} />
            </Text.SmGrayMedium>
          </div>
        )}
      </div>
    </div>
  );
}
