import REQUIRED_FIELDS, { REQUIRED_FIELDS_ARRAY } from './REQUIRED_FIELDS';
import MISSING_FIELDS, { MISSING_FIELDS_ARRAY } from './MISSING_FIELDS';

export default function getBucketParams(timestamp) {
  return {
    active: {
      $and: [
        ...REQUIRED_FIELDS_ARRAY,
        {
          cancelled: false
        },
        {
          completed: false
        },
        {
          $or: [
            {
              $and: [
                {
                  specificDate: 'Yes'
                },
                {
                  date: {
                    $gt: timestamp
                  }
                }
              ]
            },
            {
              $and: [
                {
                  specificDate: 'No'
                },
                {
                  endDate: {
                    $gt: timestamp
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    draft: {
      $and: [
        {
          cancelled: false
        },
        {
          completed: false
        },
        {
          $or: [
            ...MISSING_FIELDS_ARRAY
          ]
        }
      ]
    },
    past: {
      $or: [
        {
          cancelled: true
        },
        {
          completed: true
        }
      ],
    },
    expired: {
      $and: [
        ...REQUIRED_FIELDS_ARRAY,
        {
          cancelled: false
        },
        {
          completed: false
        },
        {
          $or: [
            {
              $and: [
                {
                  specificDate: 'Yes'
                },
                {
                  date: {
                    $lte: timestamp
                  }
                }
              ]
            },
            {
              $and: [
                {
                  specificDate: 'No'
                },
                {
                  endDate: {
                    $lte: timestamp
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  };
}
