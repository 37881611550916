import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import useRouter from '../../../hooks/useRouter';
import Loader from '../../../components/Loader';
import SolidButton from '../../../components/Button/SolidButton';
import BubbleLayout from '../BubbleLayout';
import BubbleTemplate from '../BubbleTemplate';
import Urls from '../Urls';

export default function Layout(props) {
  const { history, match } = useRouter();

  const currentUser = useUser();

  const evaluation = useConnect('evaluationEventEducator.byId', {
    id: Number(match.params.evaluationId)
  });

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  }, {
    enabled: !!evaluation.data.eventId
  });

  const activity = useConnect('tag.byId', {
    id: event?.data.activityId
  }, {
    enabled: !!event?.data.activityId
  });

  if (evaluation.state === PayloadStates.FETCHING) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="Start"
      >
        <Loader />
      </BubbleLayout>
    );
  }

  if (evaluation.state === PayloadStates.ERROR_FETCHING) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="Start"
      >
        <BubbleTemplate
          title="You are not allowed to view this page."
          subtitle="Only the person this evaluation is intended for is allowed to view it."
        >
          <div className="flex flex-col items-center space-y-6 text-right">
            <SolidButton onClick={() => history.push('/')}>
              Leave
            </SolidButton>
          </div>
        </BubbleTemplate>
      </BubbleLayout>
    );
  }

  if (event.state === PayloadStates.FETCHING) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="Start"
      >
        <Loader />
      </BubbleLayout>
    );
  }

  if (activity.state === PayloadStates.FETCHING) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="Start"
      >
        <Loader />
      </BubbleLayout>
    );
  }

  if (currentUser.id !== evaluation.data.userId) {
    return (
      <BubbleLayout
        evaluation={evaluation}
        page="Start"
      >
        <BubbleTemplate
          title="You are not allowed to view this page."
          subtitle="Only the person this evaluation is intended for is allowed to view it."
        >
          <div className="flex flex-col items-center space-y-6 text-right">
            <SolidButton onClick={() => history.push('/')}>
              Leave
            </SolidButton>
          </div>
        </BubbleTemplate>
      </BubbleLayout>
    );
  }

  return (
    <BubbleLayout
      evaluation={evaluation}
      page="Start"
    >
      <BubbleTemplate
        title="How did it go?"
        subtitle={(
          <div className="max-w-screen-md space-y-4 mx-auto">
            <div>
              We have some quick questions about your experience on the
              event <strong>{event.data.title || activity.data.name}</strong>.
            </div>
            <div>
              Your responses help us better prepare partners, ensure all participants have the
              best experience possible, and help your community secure funding for partnerships.
              Thank you!
            </div>
          </div>
        )}
      >
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton onClick={() => history.push(Urls(evaluation).STUDENTS)}>
            I'm ready!
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 font-semibold" to={Urls(evaluation).EVENT_VIEW}>
            Cancel
          </Link>
        </div>
      </BubbleTemplate>
    </BubbleLayout>
  );
};
