import React from 'react';
import { useConnect } from '@lore/query-connect';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';
import Photograph from './Photograph';

export default function Photographs(props) {
  const { evaluation } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          evaluationProjectEducatorId: evaluation.id
        }
      }
    }
  });

  return (
    <ContentCard className="space-y-4">
      <Category>
        Photographs
      </Category>
      <div className="space-y-3">
        {/*<Entry*/}
        {/*  title="Do you have permission to share these images?"*/}
        {/*  description={evaluation.data.releasePermission ? 'Yes' : 'No'}*/}
        {/*/>*/}
        {evaluationUploads.data.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            {evaluationUploads.data.map(function(evaluationUpload) {
              return (
                <Photograph
                  key={evaluationUpload.id}
                  evaluationUpload={evaluationUpload}
                />
              );
            })}
          </div>
        ) : (
          <div>
            -
          </div>
        )}
      </div>
    </ContentCard>
  );
}
