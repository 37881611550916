import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import SectionHeader from '../../../components_profiles/SectionHeader';
import { useConnect } from '../../../../hooks/@lore/query-connect';

Impact.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Impact(props) {
  const { project } = props;

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: project.data.creatorId
        }
      }
    }
  });

  if (!(
    evaluation.data.learnerReflection ||
    evaluation.data.personalReflection
  )) {
    return null;
  }

  return (
    <div className="space-y-2">
      <SectionHeader
        title="Making an impact"
      />
      {evaluation.data.learnerReflection && (
        <div className="text-base text-cs-gray-500">
          <ClampableText markup={true}>
            {evaluation.data.learnerReflection}
          </ClampableText>
        </div>
      )}
      {evaluation.data.personalReflection && (
        <div className="text-base text-cs-gray-500">
          <ClampableText markup={true}>
            {evaluation.data.personalReflection}
          </ClampableText>
        </div>
      )}
    </div>
  );
};
