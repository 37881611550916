import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import locationCondition from '../../people-partners/queryUtils/locationCondition';
import name from '../../people-partners/queryUtils/name';
import organization from '../../people-partners/queryUtils/organization';
import searchEducator from './searchEducator';
import site from './site';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          tags: {
            $where: {
              id: {
                $in: values
              }
            }
          }
        }
      }
    }
  };
}

const attributes = {
  grades: tag(),
  locationCondition,
  name,
  // organization,
  organization: site,
  practices: tag(),
  searchEducator,
  subjects: tag()
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
