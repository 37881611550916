const monthMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
};

export default function convertDateToInputDate(date) {
  if (!date) {
    const currentDate = new Date();
    date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
  }

  const [year, month, day] = date.split('T')[0].split('-');

  const monthValue = monthMap[Number(month)];
  const dayValue = Number(day);
  const yearValue = year;

  const dateValue = `${monthValue} ${dayValue}, ${yearValue}`;

  return dateValue;
}
