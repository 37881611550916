import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';
import { formatDate, formatDateRange } from '../../../utils/dateTimeUtils';

export default function Date(props) {
  const { project } = props;

  if (project.data.specificDate === 'Yes') {
    return (
      <IconSection
        icon={CalendarIcon}
        title={formatDate(project.data.date)}
      />
    );
  }

  return (
    <IconSection
      tooltip="Date range"
      icon={CalendarIcon}
      title={formatDateRange(project.data.startDate, project.data.endDate)}
    />
  );
}
