import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import User from '../../people-partners/User';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Collaborators from '../../projects-past/Project/Collaborators';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

Users.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Users(props) {
  const { project } = props;

  const MAX_CONVERSATIONS = 1000;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogManager.User.Config(user.id));
  }

  const responses = [
    'Connect',
    // 'Pending'
  ];

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: {
            $in: responses
          }
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionHeader
          title="Collaborators involved"
          subtitle="Some of the people who participated."
        />
        <Loader className="mx-7" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="People that brought the project to life"
        subtitle="These are the educators and community partners whose contributions made this project possible."
      />
      {/*<Collaborators project={project} />*/}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-6">
        <User user={creator} />
        {questions.data.map(function(question, index) {
          return (
            <UseQueryConnect key={index} _key="user.byId" params={{ id: question.data.userId }}>
              {function(user) {
                if (user.state === PayloadStates.FETCHING) {
                  return (
                    <Loader key={user.id || user.cid} />
                  );
                }

                return (
                  <User
                    key={user.id || user.cid}
                    user={user}
                  />
                );
              }}
            </UseQueryConnect>
          );
        })}
      </div>
    </div>
  );
};
