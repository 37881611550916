import React from 'react';
import ProfileMissingDialog from '../../dialogs/profile.missing';
import { useUser } from '@lore/auth';

export default function Launcher(props) {
  const currentUser = useUser();

  return (
    <ProfileMissingDialog
      user={currentUser}
      title="Ready to complete your profile?"
      subtitle="This will help connect you with others. You'll also be able to create or join projects, events, and offers."
      onCancel={props.onClose}
    />
  );
};
