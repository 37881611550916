import TagTypes from '../../constants/TagTypes';

export const MISSING_FIELDS = {
  // Missing activity
  activityId: {
    $exists: false
  },
  // Missing description
  description: {
    $equals: '',
  },
  $or: [
    // Missing date
    {
      $and: [
        {
          specificDate: 'Yes'
        },
        {
          date: {
            $exists: false
          }
        }
      ]
    },
    // Missing date range
    {
      $and: [
        {
          specificDate: 'No'
        },
        {
          $or: [
            {
              startDate: {
                $exists: false
              }
            },
            {
              endDate: {
                $exists: false
              }
            }
          ]
        }
      ]
    },
    // Missing a participant
    {
      tags: {
        $where: {
          type: TagTypes.Participant
        },
        $count: {
          operator: 'lte',
          value: 0
        }
      }
    },
    // Missing a method of interaction
    {
      tags: {
        $where: {
          type: TagTypes.InteractionType
        },
        $count: {
          operator: 'lte',
          value: 0
        }
      }
    }
  ]
}

export default MISSING_FIELDS;

export const MISSING_FIELDS_ARRAY = Object.keys(MISSING_FIELDS).map(function(key) {
  return {
    [key]: MISSING_FIELDS[key]
  };
});
