import _ from 'lodash';
import TagTypes from '../../constants/TagTypes';

const timestamp = new Date().toISOString();

const REQUIRED_FIELDS = {
  firstName: {
    $not: ''
  },
  lastName: {
    $not: ''
  },
  zipCode: {
    $not: ''
  },
  // userExpertises
  tags: {
    $where: {
      type: TagTypes.Expertise
    },
    $count: {
      operator: 'gte',
      value: 1
    }
  },
  inactive: false
  // $or: [
  //   {
  //     // both dates are in the future (not yet sleeping)
  //     $and: [
  //       { sleepingStartingAt: { $gt: timestamp } },
  //       { sleepingEndingAt: { $gt: timestamp } },
  //     ]
  //   },
  //   {
  //     // both dates are in the past (no longer sleeping)
  //     $and: [
  //       { sleepingStartingAt: { $lt: timestamp } },
  //       { sleepingEndingAt: { $lt: timestamp } },
  //     ]
  //   }
  // ]
}

export default REQUIRED_FIELDS;

export const REQUIRED_EDUCATOR_FIELDS = _.omit(REQUIRED_FIELDS, [
  // 'biography',
  'tags'
]);

export const REQUIRED_EDUCATOR_FIELDS_ARRAY = Object.keys(REQUIRED_EDUCATOR_FIELDS).map(function(key) {
  return {
    [key]: REQUIRED_EDUCATOR_FIELDS[key]
  };
});

export const REQUIRED_PARTNER_FIELDS = _.omit(REQUIRED_FIELDS, [
  // 'userSubjects',
  // 'userGrades'
]);

export const REQUIRED_PARTNER_FIELDS_ARRAY = Object.keys(REQUIRED_PARTNER_FIELDS).map(function(key) {
  return {
    [key]: REQUIRED_PARTNER_FIELDS[key]
  };
});
