import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import networks from './networks';
import activities from './activities';
import name from './name';
import school from './school';
import district from '../../projects/queryUtils/district';
import title from './title';
import locationCondition from '../../projects/queryUtils/locationCondition';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          tags: {
            $where: {
              id: {
                $in: values
              }
            }
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          tagTrees: {
            $where: {
              parentId: {
                $in: values
              }
            }
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  activities,
  ages: tag(),
  commitments: tag(),
  days: tag(),
  expertiseTypes: tag(),
  groups: tag(),
  guidances: tag(),
  interactionTypes: tag(),
  locationCondition,
  name,
  participants: tag(),
  school,
  district,
  subjects: tag(),
  times: tag(),
  title
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
