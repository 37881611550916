import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
// import RadioButtonField from '../../../forms/_fields/RadioButtonField';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import Ratings from '../Ratings';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};

// const MAX_LENGTH = 1000;

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    evaluation
  } = props;

  const [data, setData] = useState(_.pick(evaluation.data, [
    'supportedRating'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('evaluationEventEducator.update', evaluation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // experience: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <>
                  <FormSection
                    // title="Experience"
                    // subtitle="Are the students in your class primarily..."
                  >
                    <FormField name="supportedRating" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="supportedRating"
                            // label="Age group..."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: _.keys(Ratings).map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: Ratings[option]
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                </>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    Next
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
