import React, { useCallback, useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import {
  REQUIRED_EDUCATOR_FIELDS,
  REQUIRED_EDUCATOR_FIELDS_ARRAY,
  REQUIRED_PARTNER_FIELDS_ARRAY
} from '../../utils/user/REQUIRED_FIELDS';
import useLocationCondition from '../../pages/people-partners/useLocationCondition';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/people-educators/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import SolidButton from '../../components/Button/SolidButton';
import { attributeKeys } from '../../pages/people-educators/queryUtils';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import _ from 'lodash';
import SolidButtonLoader from '../../components/Button/SolidButtonLoader';
import { hasEducatorIntentQuery, hasPartnerIntentQuery } from '../../utils/query-utils/hasIntentQuery';

export default function EducatorFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));
  const [querySearch, setQuerySearch] = useState(parse(location.search));

  const debounceSetQuerySearch = useCallback(_.debounce(setQuerySearch, 250), []);

  const locationCondition = useLocationCondition(search);

  const filterFields = {
    name: 'name',
    organization: 'organization',
    searchEducator: 'searchEducator',
    grades: 'grades',
    subjects: 'subjects',
    practices: 'practices'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onCancel();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceSetQuerySearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            $and: _.remove([
              Object.keys(locationCondition).length > 0 ?
                locationCondition :
                undefined,
              {
                tags: {
                  $where: {
                    id: tag.id
                  },
                  $count: {
                    operator: 'gte',
                    value: 1
                  }
                }
              },
              hasEducatorIntentQuery,
              ...REQUIRED_EDUCATOR_FIELDS_ARRAY
            ])
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search: querySearch
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div>*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.organization).value,
                searchUtils(filterFields.searchEducator).value
              ]}
            >
              <SearchFilter
                label="Name"
                // placeholder="Enter name..."
                description="Find educators whose first or last name matches any of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="School"
                // placeholder="Enter organization name..."
                description="Find educators affiliated with a school whose name contains the phrase you type."
                value={searchUtils(filterFields.organization).value}
                onChange={searchUtils(filterFields.organization).onChangeValue}
              />
              <SearchFilter
                label="Profile"
                // placeholder="Enter word or phrase..."
                description="Find educators that have a specific phrase in their biography, or in the description of the subjects they teach."
                value={searchUtils(filterFields.searchEducator).value}
                onChange={searchUtils(filterFields.searchEducator).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          {/*<Divider />*/}
          <DisclosureTagFilter
            type={TagTypes.Grade}
            label="Grade levels"
            getParams={getTagParams}
            value={searchUtils(filterFields.grades).value}
            onChange={searchUtils(filterFields.grades).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Subject}
            label="Subjects"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Practice}
            label="Teaching practices"
            getParams={getTagParams}
            value={searchUtils(filterFields.practices).value}
            onChange={searchUtils(filterFields.practices).onChangeArray}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          {result._isPreviousData ? (
            <SolidButton onClick={onSubmit}>
              <SolidButtonLoader />
            </SolidButton>
          ) : (
            <SolidButton onClick={onSubmit}>
              Show {result.meta?.totalCount} results
            </SolidButton>
          )}
        </div>
      </div>
    </DialogTemplate>
  );
};
