import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import SectionHeader from '../../../components_profiles/SectionHeader';
import { useConnect } from '../../../../hooks/@lore/query-connect';

Journey.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Journey(props) {
  const { project } = props;

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: project.data.creatorId
        }
      }
    }
  });

  if (!evaluation.data.unfoldedReflection) {
    return null;
  }

  return (
    <div className="space-y-2">
      <SectionHeader
        title="The learning journey"
      />
      <div className="text-base text-cs-gray-500">
        <ClampableText markup={true}>
          {evaluation.data.unfoldedReflection}
        </ClampableText>
      </div>
    </div>
  );
};
