import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../../evaluation-event-educator/ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationEventPartner.byId', {
    id: Number(match.params.evaluationId)
  });

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Recommend"
      title="Would you recommend CommunityShare to other community members?"
      // description={(
      //   <>
      //     Words
      //   </>
      // )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={2} steps={2}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).OUTCOME)}
        onNext={(newData) => history.push(Urls(evaluation).END)}
        onSkip={() => history.push(Urls(evaluation).END)}
        onExit={() => history.push(Urls(evaluation).EVENT_VIEW)}
      />
    </ParentLayout>
  );
};
