import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';

const timestamp = new Date().toISOString();

export default function usePastQuestions(props) {
  const currentUser = useUser();

  return useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          response: 'Connect',
          // 'project.completed': false,
          // 'project.cancelled': false,
          $or: [
            // { 'project.completed': true },
            {
              project: {
                $where: {
                  completed: true
                }
              }
            },
            // { 'project.cancelled': true },
            {
              project: {
                $where: {
                  cancelled: true
                }
              }
            },
            {
              // 'project.specificDate': 'Yes',
              // 'project.date': {
              //   $lte: timestamp
              // },
              project: {
                $where: {
                  $and: [
                    {
                      specificDate: 'Yes'
                    },
                    {
                      date: {
                        $lte: timestamp
                      }
                    }
                  ]
                }
              }
            },
            {
              // 'project.specificDate': 'No',
              // 'project.endDate': {
              //   $lte: timestamp
              // },
              project: {
                $where: {
                  $and: [
                    {
                      specificDate: 'No'
                    },
                    {
                      endDate: {
                        $lte: timestamp
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
