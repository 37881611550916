import { useConnect } from '../../../hooks/@lore/query-connect';
import REQUIRED_FIELDS_PAST from '../projects/REQUIRED_FIELDS_PAST';

export default function usePastQuestionsForUser(user) {
  return useConnect('project.find', {
    where: {
      eager: {
        $where: {
          questions: {
            $where: {
              userId: user.id,
              response: 'Connect',
              result: {
                $in: [
                  'Participated',
                  'Contributed'
                ]
              }
            }
          },
          $and: REQUIRED_FIELDS_PAST.$and
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
