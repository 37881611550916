import _ from 'lodash';
import {
  LinkIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import useHasExpired from '../../../pages/offer-classify/useHasExpired';

export default function useNavigationLinks(offer) {
  const hasExpired = useHasExpired(offer.data.endDate);

  const isClosed = (
    hasExpired ||
    offer.data.completed ||
    offer.data.cancelled
  );

  return _.remove([
    {
      icon: LinkIcon,
      label: 'View offer',
      to: `/view`
    },
    !isClosed && {
      icon: PencilIcon,
      label: 'Edit offer',
      to: `/edit`
    },
    !isClosed && {
      icon: MagnifyingGlassIcon,
      label: 'Find educators',
      to: `/invite-populate`
    },
    !isClosed && {
      icon: UsersIcon,
      label: 'Manage educators',
      to: `/manage`
    },
    isClosed && {
      icon: UsersIcon,
      label: 'View educators',
      to: `/classify`
    }
  ], entry => entry);
};
