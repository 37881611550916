import React from 'react';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { ClockIcon, MapIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function SortBy(props) {
  const { withDistance = false, withBeginning = true, withExpiring = true } = props;

  const { location, history } = useRouter();

  const search = parse(location.search) || {};

  function onChange(order) {
    const search = parse(location.search) || {};

    search.order = order;
    delete search.page;

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  const orderMap = {
    Newest: {
      name: 'Newest',
      param: 'createdAt desc'
    },
    Beginning: {
      name: 'Start date',
      param: 'startDate asc'
    },
    Expiring: {
      name: 'Expiring',
      param: 'endDate asc'
    },
    Distance: {
      name: 'Distance',
      param: 'distance asc'
    }
  };

  const reverseOrderMap = _.mapKeys(orderMap, function(value, key) {
    return value.param;
  });

  return (
    <DropdownFilter subtitle="Sort by:" title={reverseOrderMap[search.order]?.name || 'Newest'}>
      <DropdownFilter.MenuItem
        icon={ClockIcon}
        title={orderMap.Newest.name}
        subtitle="Display recently created projects first."
        selected={!search.order || search.order === orderMap.Newest.param}
        onClick={() => onChange(orderMap.Newest.param)}
      />
      {withBeginning && (
        <DropdownFilter.MenuItem
          icon={ClockIcon}
          title={orderMap.Beginning.name}
          subtitle="Display projects by date they began."
          selected={search.order === orderMap.Beginning.param}
          onClick={() => onChange(orderMap.Beginning.param)}
        />
      )}
      {withExpiring && (
        <DropdownFilter.MenuItem
          icon={ClockIcon}
          title={orderMap.Expiring.name}
          subtitle="Display the projects expiring soon first."
          selected={search.order === orderMap.Expiring.param}
          onClick={() => onChange(orderMap.Expiring.param)}
        />
      )}
      {withDistance && (
        <DropdownFilter.MenuItem
          icon={MapIcon}
          title={orderMap.Distance.name}
          subtitle="Display projects near you first."
          selected={search.order === orderMap.Distance.param}
          onClick={() => onChange(orderMap.Distance.param)}
        />
      )}
    </DropdownFilter>
  );
}
