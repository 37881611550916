import getQueryUtils from './queryUtils';
import { REQUIRED_EDUCATOR_FIELDS, REQUIRED_EDUCATOR_FIELDS_ARRAY } from '../../utils/user/REQUIRED_FIELDS';
import { useConnect } from '@lore/query-connect';
import { hasEducatorIntentQuery } from '../../utils/query-utils/hasIntentQuery';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 16
  } = props;

  const queryUtils = getQueryUtils(search, [
    'name',
    'searchEducator',
    'organization',
    'grades',
    'subjects',
    'practices',
    'locationCondition'
  ]);

  return useConnect('user.find', {
    where: {
      eager: {
        $where: {
          // $and: [...queryUtils.where],
          // // isEducator: true,
          // isEducator: { $isEducator: true },
          // // 'tags.name': 'Educator',
          // ...REQUIRED_EDUCATOR_FIELDS,
          $and: [
            ...queryUtils.where,
            hasEducatorIntentQuery,
            ...REQUIRED_EDUCATOR_FIELDS_ARRAY
          ],
        },
        // userSubjects: {},
        // userAges: {},
        // userActivityTypes: {},
        // userExpertises: {},
        // subjects: {},
        // ages: {},
        // activityTypes: {},
        // expertises: {},
        // organizations: {}
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
