import _ from 'lodash';
import {
  LinkIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UsersIcon
} from '@heroicons/react/24/outline';

export default function useNavigationLinks(event) {
  const isClosed = (
    event.data.completed ||
    event.data.cancelled
  );

  return _.remove([
    {
      icon: LinkIcon,
      label: 'View event',
      to: `/view`
    },
    !isClosed && {
      icon: PencilIcon,
      label: 'Edit event',
      to: `/edit`
    },
    !isClosed && {
      icon: MagnifyingGlassIcon,
      label: 'Find partners',
      to: `/invite-populate`
    },
    !isClosed && {
      icon: UsersIcon,
      label: 'Manage partners',
      to: `/manage`
    },
    isClosed && {
      icon: UsersIcon,
      label: 'View partners',
      to: `/classify`
    }
  ], entry => entry);
};
