import React from 'react';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../../dialogs_new/DialogManager';
import Testimonial from '../../../evaluations/Testimonial';
import { useConnect } from '../../../../../hooks/@lore/query-connect';
import { ChatBubbleBottomCenterIcon, PhotoIcon } from '@heroicons/react/24/outline';
import EmptyState from '../../../../components/EmptyState';
import Section from '../../../../components_profiles/Section';
import Loader from '../../../../components/Loader';
import { PayloadStates } from '@lore/utils';

export default function Testimonials(props) {
  const { project } = props;

  const title = `Voices behind the experience`;
  const subtitle = `Explore reflections and testimonials from those who were part of this project.`;

  const educatorEvaluations = useConnect('evaluationProjectEducator.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          completed: true,
          testimonial: {
            $not: ''
          }
        }
      }
    }
  });

  const partnerEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          completed: true,
          testimonial: {
            $not: ''
          }
        }
      }
    }
  });

  if (
    educatorEvaluations.state === PayloadStates.FETCHING ||
    partnerEvaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <Section
        title={title}
        subtitle={subtitle}
      >
        <Loader className="mx-7" />
      </Section>
    );
  }

  if (
    educatorEvaluations.data.length === 0 &&
    partnerEvaluations.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title={title}
      subtitle={subtitle}
    >
      <div className="grid grid-cols-1 gap-4">
        {educatorEvaluations.data.map(function(evaluation, index) {
          return (
            <Testimonial
              key={`educator-${index}`}
              evaluation={evaluation}
              // onClick={() => onClickEducator(evaluation)}
              showDate={false}
            />
          );
        })}
        {partnerEvaluations.data.map(function(evaluation, index) {
          return (
            <Testimonial
              key={`partner-${index}`}
              evaluation={evaluation}
              // onClick={() => onClickPartner(evaluation)}
              showDate={false}
            />
          );
        })}
      </div>
    </Section>
  );
}
