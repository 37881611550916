import getQueryUtils, { attributeKeys } from './queryUtils';
// import REQUIRED_FIELDS_PUBLIC_PROJECT from './REQUIRED_FIELDS_PUBLIC_PROJECT';
// import REQUIRED_FIELDS_PUBLIC_EVENT from './REQUIRED_FIELDS_PUBLIC_EVENT';
import REQUIRED_FIELDS_PUBLIC_PROJECT from '../projects/REQUIRED_FIELDS_PUBLIC';
import REQUIRED_FIELDS_PUBLIC_EVENT from '../events/REQUIRED_FIELDS_PUBLIC';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 4,
    showPast = false
  } = props;

  const queryUtils = getQueryUtils(search, attributeKeys);

  return useConnect('opportunity.find', {
    where: {
      eager: {
        $where: {
          $and: [
            ...queryUtils.where,
            {
              $or: [
                {
                  project: {
                    $where: {
                      $and: REQUIRED_FIELDS_PUBLIC_PROJECT.$and
                    }
                  }
                },
                {
                  event: {
                    $where: {
                      $and: REQUIRED_FIELDS_PUBLIC_EVENT.$and
                    }
                  }
                }
              ]
            }
          ]
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
