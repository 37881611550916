import React from 'react';
import ActionBarMenu from './ActionBarMenu';
import PageSelectMenu from './PageSelectMenu';

export default function ActionBar(props) {
  const { offer } = props;

  return (
    <div>
      <div className="hidden lg:block">
        <ActionBarMenu offer={offer} />
      </div>
      <div className="block lg:hidden">
        <PageSelectMenu offer={offer} />
      </div>
    </div>
  );
};
