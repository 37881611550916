import React from 'react';
import PropTypes from 'prop-types';
import FormTemplate from '../../dialogs_common/OnboardingTemplate';
import SolidButton from '../../components/Button/SolidButton';
import useRouter from '../../hooks/useRouter';
import Card from '../../components/Card';

ProfileType.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function ProfileType(props) {
  const { history } = useRouter();

  return (
    <Card>
      <FormTemplate
        className="w-full"
        title="Want to update the way you connect on CommunityShare?"
        subtitle={(
          <div className="space-y-2">
            <div>
              Whether you're looking for partners to bring real-world learning to your
              learners, to share your skills and knowledge, or both, we're here to help.
            </div>
            <div>
              Click below and we'll guide you through updating your profile so you can
              continue making connections!
            </div>
          </div>
        )}
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={() => history.push('/setup/profile')}>
              Change profile type
            </SolidButton>
          </div>
        )}
      />
    </Card>
  );
};
