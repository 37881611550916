import React from 'react';
import Markdown from './Markdown';
import useNetwork from '../hooks/useNetwork';
import Banner from './Banner';
import useIntents from '../hooks/useIntents';

export default function AnnouncementBanner(props) {
  const { isEducator, isPartner } = useIntents();

  const network = useNetwork();

  const banners = [];

  const educatorBanner = `${network.data.educatorBanner}`.trim();
  const partnerBanner = `${network.data.partnerBanner}`.trim();

  if (!educatorBanner && !partnerBanner) {
    return null;
  }

  if (
    isEducator &&
    isPartner &&
    educatorBanner === partnerBanner
  ) {
    return (
      <Banner key="educator-partner-banner">
        <Markdown white={true} text={educatorBanner} />
      </Banner>
    );
  }

  if (
    isEducator &&
    educatorBanner
  ) {
    banners.push(
      <Banner key="educator-banner">
        <Markdown white={true} text={educatorBanner} />
      </Banner>
    );
  }

  if (
    isPartner &&
    partnerBanner
  ) {
    banners.push(
      <Banner key="partner-banner">
        <Markdown white={true} text={partnerBanner} />
      </Banner>
    );
  }

  if (banners.length > 0) {
    return banners;
  }

  return null;
};
