import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function PersonalReflections(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Personal Reflections
        <div className="text-base font-normal text-cs-gray-500">
          Please rate any changes you observed within yourself as a result of the interaction.
        </div>
      </Category>
      <div className="space-y-3">
        <Entry
          title="CommunityShare expanded your network of community partners."
          description={evaluation.data.expandedRating || '-'}
        />
        <Entry
          title="CommunityShare increased your confidence to design learning experiences/projects that engage your community and students."
          description={evaluation.data.confidenceRating || '-'}
        />
        <Entry
          title="Extent to which you feel more supported by your community as a result of CommunityShare."
          description={evaluation.data.supportedRating || '-'}
        />
        <Entry
          title="The project/event you did increased your excitement and desire to keep teaching."
          description={evaluation.data.excitementRating || '-'}
        />
      </div>
    </ContentCard>
  );
}
