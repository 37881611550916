export const hasPartnerIntentQuery = {
  tags: {
    $where: {
      name: 'Partner'
    },
    $count: {
      operator: 'gte',
      value: 1
    }
  }
};

export const hasEducatorIntentQuery = {
  tags: {
    $where: {
      name: 'Educator'
    },
    $count: {
      operator: 'gte',
      value: 1
    }
  }
};
