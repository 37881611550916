import getQueryUtils from './queryUtils';

export default function useInteractionTypesFilterParam(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'interactionTypes'
  ]);

  const values = queryUtils.params.interactionTypes;

  if (values.length > 0) {
    return {
      $or: [
        {
          project: {
            $where: {
              tags: {
                $where: {
                  id: {
                    $in: values
                  }
                }
              }
            }
          }
        },
        {
          event: {
            $where: {
              tags: {
                $where: {
                  id: {
                    $in: values
                  }
                }
              }
            }
          }
        }
      ]
    };
  }
}
