export default function(evaluation) {
  return {
    START: `/evaluation-event-educator/${evaluation.id}/start`,
    STUDENTS: `/evaluation-event-educator/${evaluation.id}/students`,

    ENGAGED: `/evaluation-event-educator/${evaluation.id}/engaged`,
    SKILLS: `/evaluation-event-educator/${evaluation.id}/skills`,
    AWARENESS: `/evaluation-event-educator/${evaluation.id}/awareness`,
    PRACTICED: `/evaluation-event-educator/${evaluation.id}/practiced`,

    EXPANDED: `/evaluation-event-educator/${evaluation.id}/expanded`,
    CONFIDENCE: `/evaluation-event-educator/${evaluation.id}/confidence`,
    SUPPORTED: `/evaluation-event-educator/${evaluation.id}/supported`,
    EXCITEMENT: `/evaluation-event-educator/${evaluation.id}/excitement`,

    UPLOADS: `/evaluation-event-educator/${evaluation.id}/uploads`,
    RECOMMEND: `/evaluation-event-educator/${evaluation.id}/recommend`,
    PARTICIPATE: `/evaluation-event-educator/${evaluation.id}/participate`,

    UNFOLDED_REFLECTION: `/evaluation-event-educator/${evaluation.id}/unfolded-reflection`,
    LEARNER_REFLECTION: `/evaluation-event-educator/${evaluation.id}/learner-reflection`,
    PERSONAL_REFLECTION: `/evaluation-event-educator/${evaluation.id}/personal-reflection`,
    OTHER_REFLECTION: `/evaluation-event-educator/${evaluation.id}/other-reflection`,

    END: `/evaluation-event-educator/${evaluation.id}/end`,
    EVENT_MANAGE: `/event/${evaluation.data.eventId}/manage`,
    EVENT_VIEW: `/event/${evaluation.data.eventId}/view`
  }
};
