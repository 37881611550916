import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import SelectField from '../../../forms/_fields_v2/SelectField';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'gender',
    'ethnicity',
    'yearOfBirth',
    'showDemographics'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-6">
                  <FormField name="gender" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <SelectField
                        label="Gender identity"
                        value={field.value}
                        error={field.error}
                        placeholder="Select a value..."
                        options={{
                          data: [
                            'Man',
                            'Woman',
                            'Agender',
                            'Gender Fluid',
                            'Gender Non-conforming',
                            'Genderqueer',
                            'Non-binary',
                            'Questioning',
                            'Self Identify Another Option',
                            'Prefer Not to Say'
                          ].map((option) => {
                            return {
                              id: option,
                              data: {
                                name: option
                              }
                            }
                          })
                        }}
                        optionLabel="name"
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                  <FormField name="ethnicity" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <SelectField
                        label="Race and ethnicity"
                        value={field.value}
                        error={field.error}
                        placeholder="Select a value..."
                        options={{
                          data: [
                            'Native American / American Indian or Alaska Native',
                            'Asian or Asian American',
                            'Black or African American',
                            'Hispanic or Latino',
                            'Native Hawaiian or Other Pacific Islander',
                            'White',
                            'Multiracial',
                            'Other',
                            'Prefer Not to Say'
                          ].map((option) => {
                            return {
                              id: option,
                              data: {
                                name: option
                              }
                            }
                          })
                        }}
                        optionLabel="name"
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                  <FormField name="yearOfBirth" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <StringField
                        label="Year of birth"
                        value={field.value}
                        error={field.error}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                  <FormField name="showDemographics" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <CheckboxField
                        label="I give permission to share my demographic information in my profile."
                        checked={field.value}
                        error={field.error}
                        onChange={(event) => field.onChange(event.target.checked)}
                      />
                    )}
                  </FormField>
                </div>
              )}
              // footer={(
              //   <>
              //     <PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
              //       {buttonText}
              //     </PrimaryButton>
              //   </>
              // )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
