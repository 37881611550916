import React from 'react';

export default function Header(props) {
  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
          Please share which organization (school, work, or volunteer) is most connected
          to why you're joining.
        </h5>
        <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">
          {/*<span className="block mb-2">*/}
          {/*  Tell us the organization you belong to that is most relevant to your reason for joining us.*/}
          {/*</span>*/}
          {/*<span className="block mb-2">*/}
          {/*  This could be your school, employer, or volunteer organization.*/}
          {/*</span>*/}
          <span className="block">
            No organization? No problem - you can always add it later!
          </span>
        </h6>
      </div>
    </div>
  );
};
