import React from 'react';
import useDialogConfig from '../../_hooks/useDialogConfig';
import useDialogRouter from '../../_hooks/useDialogRouter';
import ActionBarContainer from '../../_components_action_bar/ActionBarContainer';
import ActionBarButton from '../../_components_action_bar/ActionBarButton';
import ActionBarMenuBase from '../../_components_action_bar/ActionBarMenuBase';
import useNavigationLinks from './useNavigationLinks';
import useActionLinks from './useActionLinks';

export default function ActionBarMenu(props) {
  const { project } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);
  const navigationLinks = useNavigationLinks(project);
  const actionLinks = useActionLinks(project);

  return (
    <ActionBarContainer>
      {navigationLinks.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <ActionBarButton
            key={index}
            label={link.label}
            icon={link.icon}
            onClick={() => router.navigate(link.to)}
            isActive={active}
          />
        );
      })}
      <ActionBarMenuBase links={actionLinks} />
    </ActionBarContainer>
  );
};
